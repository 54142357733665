import { Button, ButtonGroup, Menu, MenuItem, Select, useMediaQuery, useTheme, Drawer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import useDepsContainer from 'hooks/useDepsContainer';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppTheme, Colors } from 'styles/theme';
import Flex from './common/Flex';
import Text from './common/Text';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'utils/helper';
import { useCallback, useMemo } from 'react';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { LogoIcon, LogoTextSvg } from 'assets/icons';
import { AndroidAppSharedButton, IOSAppSharedButton, GroupSvg, MenuEnter } from 'assets/icons';
import i18n, { LangKeys } from 'locales/i18n';
import { ChinaFlag, EnglandFlagIcon, IndonesiaFlag, JapanFlag, KoreanFlag, SpainFlag, VietnamFlag } from 'assets/icons';

interface AppMenuProps {
  style?: React.CSSProperties;
}

const AppMenu = (props: AppMenuProps) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const styles = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);

  const cachedI18n = useMemo(() => i18n, [i18n]);
  const [open, setOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState<any>(null);
  const [selectedLangName, setSelectedLangName] = useState<any>(null);
  const [langModalShow, setLangModalShow] = useState(false);
  const [langListOpen, setLangListOpen] = useState(false);

  function toAppStore() {
    window.open('https://apps.apple.com/us/app/pumpkin-safe-social/id1672017600');
  }

  function toGooglePlay() {
    window.open('https://play.google.com/store/apps/details?id=com.pumpkin.date');
  }

  const handleLangClick = lang => {
    setSelectedLang(lang);
    setSelectedLangName(getLangByKey(lang));
    i18n.changeLanguage(lang);
    uiStore.language = lang;
    localStorage.setItem('@lang', lang);
    setLangModalShow(false);
  };

  const handleLangMobileClick = lang => {
    setSelectedLang(lang);
    setSelectedLangName(getLangByKey(lang));
    i18n.changeLanguage(lang);
    uiStore.language = lang;
    localStorage.setItem('@lang', lang);
    setLangListOpen(false);
  };

  useEffect(() => {
    const langCached = getDefaultLangFromUrl()
      ? getDefaultLangFromUrl()
      : localStorage.getItem('@lang')
      ? localStorage.getItem('@lang')
      : getDefaultLang();
    i18n.changeLanguage(langCached || LangKeys.en);
    uiStore.language = langCached || LangKeys.en;
    setSelectedLang(langCached || LangKeys.en);
    setSelectedLangName(getLangByKey(langCached || LangKeys.en));
    localStorage.setItem('@lang', langCached || LangKeys.en);
  }, []);

  const getDefaultLangFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (lang === 'zh_TW') {
      return LangKeys.ct;
    } else if (lang === 'zh_CN') {
      return LangKeys.cs;
    } else if (lang === 'en_US') {
      return LangKeys.en;
    } else if (lang === 'vi_VN') {
      return LangKeys.vn;
    } else if (lang === 'ja_JP') {
      return LangKeys.jp;
    } else if (lang === 'ko_KR') {
      return LangKeys.kr;
    } else if (lang === 'id_ID') {
      return LangKeys.in;
    } else if (lang === 'th_TH') {
      return '';
    } else {
      return '';
    }
  };

  const getDefaultLang = () => {
    const lang = navigator.language;
    if (lang === 'zh-TW') {
      return LangKeys.ct;
    } else if (lang === 'zh' || lang.indexOf('zh-') != -1) {
      return LangKeys.cs;
    } else if (lang === 'en' || lang.indexOf('en-') != -1) {
      return LangKeys.en;
    } else if (lang === 'vi') {
      return LangKeys.vn;
    } else if (lang === 'ja') {
      return LangKeys.jp;
    } else if (lang === 'ko') {
      return LangKeys.kr;
    } else if (lang === 'id') {
      return LangKeys.in;
    } else if (lang === 'es' || lang.indexOf('es-') != -1) {
      return LangKeys.sp;
    } else {
      return LangKeys.en;
    }
  };

  const getStoreLang = () => {
    const lang = getDefaultLangFromUrl()
      ? getDefaultLangFromUrl()
      : localStorage.getItem('@lang')
        ? localStorage.getItem('@lang')
        : getDefaultLang();
    if (lang === 'cs') {
      return "zh_CN"
    } else if (lang === 'ct') {
      return "zh_TW"
    } else if (lang === 'vi' || lang === 'vn') {
      return "vi_VN"
    } else if (lang === 'jp') {
      return "ja_JP"
    } else if (lang === 'kr') {
      return "ko_KR"
    } else if (lang === 'in') {
      return "id_ID"
    } else if (lang === 'en' || lang === 'sp') {
      return "en_US"
    } else {
      return "en_US"
    }
  };


  const languageOptions = [
    {
      key: LangKeys.en,
      name: 'English',
      icon: <EnglandFlagIcon width={32} height={18} />
    },
    {
      key: LangKeys.vn,
      name: 'Tiếng Việt',
      icon: <VietnamFlag width={32} height={18} />
    },
    {
      key: LangKeys.cs,
      name: '简体中文',
      icon: <ChinaFlag width={32} height={18} />
    },
    {
      key: LangKeys.ct,
      name: '繁體中文',
      icon: <ChinaFlag width={32} height={18} />
    },
    {
      key: LangKeys.in,
      name: 'Bahasa',
      icon: <IndonesiaFlag width={32} height={18} />
    },
    {
      key: LangKeys.kr,
      name: '한국어',
      icon: <KoreanFlag width={32} height={18} />
    },
    {
      key: LangKeys.jp,
      name: '日本語',
      icon: <JapanFlag width={32} height={18} />
    },
    {
      key: LangKeys.sp,
      name: 'Español',
      icon: <SpainFlag width={32} height={18} />
    }
  ];

  useEffect(() => {
    if (!mdDown) setOpen(false);
  }, [mdDown]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const toAnchor = (idName: string) => (event: any) => {
    setTimeout(() => {
      scrollIntoView(idName);
      setOpen(false);
    }, 500);
  };

  const getLangByKey = key => {
    const language = languageOptions.find(lang => lang.key === key);
    return language ? language.name : null;
  };

  function MenuList() {
    return (
      <Flex width={'80vw'} height={'100%'} column className={styles.mobileDrawer}>
        <Flex className={styles.mobileHeader}>
          <LogoIcon />
          <Flex
            className={styles.closeDrawer}
            onClick={() => {
              setOpen(false);
            }}
          ></Flex>
        </Flex>
        <Flex className={styles.mobileMenu} column>
          <Flex
            className={`${location.pathname === '/' ? styles.mobileMenuTextActive : styles.mobileMenuText} ${
              uiStore.language
            }`}
            pb={4}
            onClick={() => {
              history.push('/');
            }}
          >
            {cachedT('Home')}
          </Flex>
          <Flex
            className={`${location.pathname === '/superlike' ? styles.mobileMenuTextActive : styles.mobileMenuText} ${
              uiStore.language
            }`}
            pb={4}
            onClick={() => {
              history.push('/superlike');
            }}
          >
            {cachedT('SuperlikeTopMenu')}
          </Flex>
          <Flex
            className={`${location.pathname === '/technology' ? styles.mobileMenuTextActive : styles.mobileMenuText} ${
              uiStore.language
            }`}
            pb={4}
            onClick={() => {
              history.push('/technology');
            }}
          >
            {cachedT('Technology')}
          </Flex>
          <Flex
            className={`${location.pathname === '/whyus' ? styles.mobileMenuText : styles.mobileMenuText} ${
              uiStore.language
            }`}
            pb={4}
            onClick={() => {
              window.open('deck.pdf', 'deck', 'noopener,noreferrer');
            }}
          >
            Deck
          </Flex>
          <Flex
            className={`${styles.mobileMenuText} ${uiStore.language}`}
            pb={4}
            onClick={() => {
              window.open('https://docs.pumpkin.xyz');
            }}
          >
            {cachedT('Docs')}
          </Flex>
          {
            mdDown ? null : (
              <Flex
                className={`${styles.mobileMenuText} ${uiStore.language}`}
                pb={4}
                onClick={() => {
                  window.open(`${process.env.REACT_APP_STORE_URL}?lang=${getStoreLang()}`);
                }}
              >
                {cachedT('Store')}
              </Flex>
            )
          }
          <Flex
            className={styles.mobileMenuText}
            pb={4}
            onClick={() => {
              setLangListOpen(true);
              setOpen(false);
            }}
            style={{
              width: '100%'
            }}
          >
            <em className={styles.langLogo}></em>
            {selectedLangName}
            <em className={styles.selectArrow}></em>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  function LangList() {
    return (
      <Flex width={'80vw'} height={'100%'} column className={styles.mobileDrawer}>
        <Flex className={styles.mobileLangTitle}>
          <em
            onClick={() => {
              setLangListOpen(false);
            }}
          ></em>
          {cachedT('MobileLangTitle')}
        </Flex>
        <Flex className={styles.mobileMenu} column>
          {languageOptions.map((item, index) => (
            <MenuItem
              value={item.key}
              key={item.key}
              className={`${styles.menuLang} ${selectedLang === item.key ? styles.langSelected : ''}`}
              onClick={() => handleLangMobileClick(item.key)}
            >
              <span className={styles.langList}>{item.name}</span>
              <em className={styles.langSelectedIcon}></em>
            </MenuItem>
          ))}
        </Flex>
      </Flex>
    );
  }

  if (mdDown) {
    return (
      <Flex className='p-0'>
        <Button
          onClick={toggleDrawer(true)}
          style={{
            width: '2.5rem', 
            height: '2.5rem', 
            minWidth: 0}}
        >
          <Flex color={'white'}>
            <MenuEnter />
          </Flex>
        </Button>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)} className={styles.drawer}>
          <MenuList />
        </Drawer>
        <Drawer anchor={'right'} open={langListOpen} className={styles.drawer}>
          <LangList />
        </Drawer>
      </Flex>
    );
  }

  return (
    <Flex flexWrap={'wrap'} width={'100%'}>
      <ButtonGroup>
        <Button
          variant="text"
          className={location.pathname === '/' ? styles.menuBtnActive : styles.menuBtn}
          onClick={() => {
            history.push('/');
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('Home')}
          </Text>
        </Button>
        <Button
          variant="text"
          className={location.pathname === '/superlike' ? styles.menuBtnActive : styles.menuBtn}
          onClick={() => {
            history.push('/superlike');
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('SuperlikeTopMenu')}
          </Text>
        </Button>
        <Button
          variant="text"
          className={location.pathname === '/technology' ? styles.menuBtnActive : styles.menuBtn}
          onClick={() => {
            history.push('/technology');
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('Technology')}
          </Text>
        </Button>
        <Button
          variant="text"
          className={location.pathname === '/whyus' ? styles.menuBtn : styles.menuBtn}
          onClick={() => {
            window.open('deck.pdf', 'deck', 'noopener,noreferrer');
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            Deck
          </Text>
        </Button>
        <Button
          variant="text"
          className={styles.menuBtn}
          onClick={() => {
            window.open('https://docs.pumpkin.xyz');
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('Docs')}
          </Text>
        </Button>
        <Button
          variant="text"
          className={styles.menuBtn}
          onClick={() => {
            window.open(`${process.env.REACT_APP_STORE_URL}/my/center?lang=${getStoreLang()}`);
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('upload_music')}
          </Text>
        </Button>

        <Button
          variant="text"
          className={styles.menuBtn}
          onClick={() => {
            window.open(`${process.env.REACT_APP_STORE_URL}?lang=${getStoreLang()}`);
          }}
        >
          <Text className={`${styles.menuText} ${uiStore.language}`} color={'textColor'}>
            {cachedT('Wallet')}
          </Text>
        </Button>
        {/* <Flex mx={2} cursorPointer onClick={toGooglePlay}>
          <AndroidAppSharedButton width={mdDown ? 155 : 155} height={mdDown ? 46 : 46} />
        </Flex>
        <Flex mx={2} cursorPointer onClick={toAppStore}>
          <IOSAppSharedButton width={mdDown ? 155 : 155} height={mdDown ? 46 : 46} />
        </Flex> */}
        <Button className={styles.lang} onClick={() => setLangModalShow(true)}></Button>
      </ButtonGroup>

      {langModalShow && (
        <Flex className={styles.overlay}>
          <Flex className={styles.modal} column>
            <Flex className={styles.modalTitle}>{cachedT('ChooseLanguages')}</Flex>
            <Flex className={styles.modalContent}>
              {languageOptions.map((item, index) => (
                <MenuItem
                  value={item.key}
                  key={item.key}
                  className={`${styles.menuLang} ${selectedLang === item.key ? styles.langSelected : ''}`}
                  onClick={() => handleLangClick(item.key)}
                >
                  <span className={styles.langList}>{item.name}</span>
                  <em className={styles.langSelectedIcon}></em>
                </MenuItem>
              ))}
            </Flex>
            <Flex className={styles.modalClose} onClick={() => setLangModalShow(false)}></Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    bg: {},
    menuBtn: {
      //marginLeft: '1rem !important'
      marginRight: '40px !important',

      '&:hover': {
        background: '#FFF !important'
      }
    },
    menuBtnActive: {
      marginRight: '40px !important',
      borderBottom: '4px solid #E85130 !important',
      borderRadius: '0 !important',

      '&:hover': {
        background: '#FFF !important'
      }
    },
    menuText: {
      fontFamily: "'Nohemi' !important",
      fontStyle: 'normal !important',
      fontWeight: '300 !important',
      fontSize: '18px !important',
      lineHeight: '155% !important',
      letterSpacing: '-0.03em !important',
      textTransform: 'none',
      color: '#18181B !important',

      '&:hover': {
        background: '#FFF !important'
      },

      '&.vn': {
        fontFamily: "'Work Sans' !important"
      }
    },
    select: {
      '&:after': {
        borderBottomColor: 'white'
      },
      '& .MuiSvgIcon-root': {
        color: 'white'
      }
    },
    lang: {
      width: '40px',
      height: '40px',
      background: 'url(/images/home-header-global.png) center no-repeat',
      backgroundSize: 'cover',
      // marginLeft: '40px !important',
      border: 'none !important'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.25)',
      display: 'none'
    },
    modal: {
      display: 'none',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      background: '#fff',
      boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
      width: '604px',
      height: '434px',
      borderRadius: '24px',
      padding: '24px'
    },
    modalTitle: {
      color: '#000',
      fontFamily: 'Lexend',
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: ' 155%',
      letterSpacing: '-0.96px',
      marginBottom: '40px'
    },
    modalContent: {
      width: '596px',
      flexWrap: 'wrap'
    },
    menuLang: {
      width: '258px',
      height: '56px',
      marginRight: '40px !important',
      marginBottom: '24px !important',
      color: '#000'
    },
    langList: {
      color: '#18181B',
      fontSize: '18px',
      fontWeight: '300',
      lineHeight: '155%',
      letterSpacing: '-0.54px'
    },
    langSelected: {
      borderRadius: '8px !important',
      border: '2px solid #000 !important',

      '& span': {
        color: '#E85130 !important'
      },

      '& em': {
        display: 'block'
      }
    },
    langSelectedIcon: {
      width: '20px',
      height: '20px',
      background: 'url(/images/home-lang-selected.svg) center no-repeat',
      position: 'absolute',
      right: '20px',
      display: 'none'
    },
    modalClose: {
      width: '32px',
      height: '32px',
      background: 'url(/images/home-lang-close.svg) center no-repeat',
      position: 'absolute',
      right: '20px',
      cursor: 'pointer'
    },
    drawer: {
      width: '80vw',
      transition: 'right 0.3s ease'
    },
    mobileDrawer: {
      padding: '24px',
      position: 'relative'
    },
    mobileHeader: {
      height: '40px',
      position: 'relative',
      marginBottom: '40px'
    },
    closeDrawer: {
      width: '32px',
      height: '32px',
      background: 'url(/images/home-lang-close.svg) center no-repeat',
      position: 'absolute',
      top: '0px',
      right: '-12px'
    },
    mobileMenuText: {
      height: '28px',
      marginBottom: '40px',
      color: '#232429',
      fontSize: '18px',
      fontWeight: '300',
      lineHeight: '155%',
      letterSpacing: '-0.54px',
      flexGrow: '1',
      position: 'relative'
    },
    mobileMenuTextActive: {
      height: '28px',
      marginBottom: '40px',
      color: '#232429',
      fontSize: '18px',
      fontWeight: '300',
      lineHeight: '155%',
      letterSpacing: '-0.54px',
      display: 'inline-block !important',
      borderBottom: '4px solid #E85130 !important'
    },
    mobileMenu: {
      alignItems: 'flex-start',
      width: '100%',
      minWidth: '0'
    },
    langLogo: {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      background: 'url(/images/home-header-global.png) center no-repeat',
      backgroundSize: 'cover',
      border: 'none !important',
      verticalAlign: 'top',
      marginRight: '8px'
    },
    selectArrow: {
      display: 'block',
      width: '24px',
      height: '24px',
      background: 'url(/images/mobile/lang-select-arrow.svg) center no-repeat',
      backgroundSize: 'cover',
      position: 'absolute',
      border: 'none !important',
      right: '0',
      top: '0'
    },
    mobileLangTitle: {
      height: '32px',
      position: 'relative',
      marginBottom: '40px',
      color: '#000',
      fontFamily: 'Lexend',
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '32px',
      letterSpacing: ' -0.72px',

      '& em': {
        display: 'inline-block',
        width: '32px',
        height: '32px',
        background: 'url(/images/mobile/lang-arrow-left.svg) center no-repeat',
        backgroundSize: 'cover',
        marginRight: '8px',
        verticalAlign: 'top'
      }
    }
  })
);

export default observer(AppMenu);
