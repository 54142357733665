import { AppBar, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoFullIcon, LogoIcon, PumpkinCoinsSvg, WalletEnter } from 'assets/icons';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppTheme, Colors } from 'styles/theme';
import { scrollIntoView } from 'utils/helper';
import AppMenu from './AppMenu';
import Flex from './common/Flex';
import LangSelect from 'components/LangSelect';
import useDepsContainer from 'hooks/useDepsContainer';
import TERMS from '../seo/meta/TERMS';
interface HeaderProps {
  style?: React.CSSProperties;
  bgcolor?: string;
}

const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const { uiStore } = useDepsContainer();

  function toMall() {
    history.push('/mall');
  }

  function RightMenu() {
    return (
      <>
        {!mdDown && (
          <Flex>
            <AppMenu />
          </Flex>
        )}
        {/* <Flex
          bgcolor={props.bgcolor ? "" : "#232429"}
          p={1}
          mr={mdDown ? 0 : 4}
          color={"white"}
          borderRadius={".5rem"}
        >
          <LangSelect
            SelectSx={{
              background: props.bgcolor ? "" : "#232429",
              color: "white",
            }}
          />
        </Flex> */}
        {/* {uiStore.isLogin ? (
          <Flex
            style={{
              background: 'var(--gradient-6, linear-gradient(90deg, #FD746C 0%, #FF9068 100%))',
              padding: mdDown ? '5px 26px' : '0 56px',
              borderRadius: '80px',
              alignItems: 'center',
              color: 'black'
            }}
            cursorPointer
            onClick={toMall}
          >
            <Flex>Store</Flex>
          </Flex>
        ) : null} */}
        {mdDown && (
          <Flex>
            <AppMenu />
          </Flex>
        )}
      </>
    );
  }

  return (
    <AppBar
      position="static"
      style={{
        height: mdDown ? 72 : 72,
        background: props.bgcolor ? props.bgcolor : Colors.header,
        justifyContent: 'center',
        zIndex: 2,
        boxShadow: 'none'
      }}
    >
      <Flex
        centerY
        width={mdDown ? '100vw' : '100%'}
        maxWidth={mdDown ? 'auto' : '1280px'}
        justifyContent={'space-between'}
        margin={'0 auto'}
        px={mdDown ? 6 : 0}
      >
        <Flex
          center
          cursorPointer
          onClick={() => {
            history.push('/');
            setTimeout(
              () => {
                scrollIntoView('overview');
              },
              pathName === '/' ? 100 : 500
            );
          }}
        >
          <IconButton edge="start" className={styles.menuButton} color="inherit" aria-label="menu">
            {mdDown ? <LogoIcon /> : <LogoFullIcon />}
          </IconButton>
        </Flex>
        {mdDown ? (
          <Flex centerY>
            <IconButton style={{margin: 12}} edge="start" className={styles.menuButton} color="inherit" aria-label="menu" onClick={() => {
            window.open(`${process.env.REACT_APP_STORE_URL}?lang=${uiStore.language}}`);
          }}>
              <WalletEnter style={{width: '2.5rem', height: '2.5rem'}}/>
            </IconButton>
            <RightMenu />
          </Flex>
        ) : (
          <Flex>
            <RightMenu />
          </Flex>
        )}
      </Flex>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    bg: {},
    menuButton: {}
  })
);

export default observer(Header);
